<div class="grid sm:grid-cols-12 gap-4 w-full mt-8 border border-1 p-8 bg-white">

    <!-- Categoria -->
    <div class="sm:col-span-5 ">
        <div class="text-base mb-1 text-primary">Categoria</div>
        <div class="text-2xl font-medium hidden sm:inline">{{item.category_flat.des}} ({{item.category_flat.short_label}})</div>
        <div class="text-2xl font-medium sm:hidden">{{item.category_flat.short_label}}</div>
    </div>

    <!--Matricola-->
    <div class="sm:col-span-2">
        <div class="text-base mb-1 text-primary">Matricola</div>
        <div class="text-2xl font-medium">{{item.serial_number}}</div>
    </div>

    <!--Produttore-->
    <div class="sm:col-span-3">
        <div class="text-base mb-1 text-primary">Produttore</div>
        <div class="text-2xl font-medium">{{item.producer?.label}}</div>
    </div>

    <!--Anno produzione-->
    <div class="sm:col-span-2">
        <div class="text-base mb-1 text-primary">Anno produzione</div>
        <div class="text-2xl font-medium">{{item.production_year}}</div>
    </div>

    <!-- Note -->
    <div class="sm:col-span-12" *ngIf="item.note">
        <div class="text-base mb-1 text-primary">Note</div>
        <div class="text-base font-medium text-red-600">{{item.note}}</div>
    </div>

</div>
