import { Component } from '@angular/core';
import {MaintenanceStatus, MaintenanceStatusService} from "../../services/maintenance-status.service";

@Component({
  selector: 'app-maintenance-statusbar',
  templateUrl: './maintenance-statusbar.component.html',
  styleUrls: ['./maintenance-statusbar.component.scss']
})
export class MaintenanceStatusbarComponent {


    constructor(
        public maintenanceStatusService: MaintenanceStatusService
    ) {
    }

    protected readonly MaintenanceStatus = MaintenanceStatus;
}
