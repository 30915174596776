import {Injectable} from '@angular/core';

export enum MaintenanceStatus {
    inactive,
    active
}

@Injectable({
    providedIn: 'root'
})
export class MaintenanceStatusService {

    status: MaintenanceStatus = 0;


}
