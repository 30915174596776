/* tslint:disable:max-line-length */
import {FuseNavigationItem} from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        type: 'divider'
    },
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'mat_outline:dashboard',
        link: 'dashboard'
    },
    {
        type: 'divider'
    },
    {
        id: 'gestione-utenti',
        title: 'Gestione Utenti',
        subtitle: 'Gestisci gli utenti interni',
        type: 'group',
        icon: 'heroicons_outline:users',
        children: [
            {
                id: 'gestione-utenti.add',
                title: 'Nuovo utente',
                type: 'basic',
                icon: 'mat_outline:person_add_alt',
                link: '/manage-users/create'
            },
            {
                id: 'gestione-utenti.list',
                title: 'Lista utenti',
                type: 'basic',
                icon: 'mat_outline:people_alt',
                link: '/manage-users/list'
            },
        ]
    },
    {
        type: 'divider'
    },
    {
        id: 'gestione-clienti',
        title: 'Gestione Clienti',
        subtitle: 'Gestisci i clienti',
        type: 'group',
        icon: 'heroicons_outline:users',
        children: [
            {
                id: 'gestione-clienti.add',
                title: 'Nuovo cliente',
                type: 'basic',
                icon: 'mat_outline:add_business',
                link: '/customers/create'
            },
            {
                id: 'gestione-clienti.list',
                title: 'Lista clienti',
                type: 'basic',
                icon: 'mat_outline:maps_home_work',
                link: '/customers/list'
            },
            {
                id: 'gestione-clienti.list.so',
                title: 'Sedi operative',
                type: 'basic',
                icon: 'mat_outline:room_preferences',
                link: '/customers/sites'
            },
        ]
    },
    {
        type: 'divider'
    },
    {
        id: 'manutenzione',
        title: 'Manutenzioni',
        subtitle: 'Pianifica e gestisci le manutenzioni',
        type: 'group',
        icon: 'mat_outline:build',
        children: [
            {
                id: 'manutenzione.pianifica',
                title: 'Pianifica manutenzioni',
                type: 'basic',
                icon: 'mat_outline:edit_calendar',
                link: '/maintenances/maintenance-planning'
            },
            {
                id: 'manutenzione.calendario',
                title: 'Calendario manutenzioni',
                type: 'basic',
                icon: 'heroicons_outline:calendar',
                link: '/maintenances/maintenance-calendar'
            },
            {
                id: 'manutenzione.manutenzioni_avviate',
                title: 'Manutenzioni in corso',
                type: 'basic',
                icon: 'mat_outline:build',
                link : '/maintenances/maintenance-sessions'
            }
        ]
    },
    {
        type: 'divider'
    },
    {
        id: 'qrcode',
        title: 'QR CODE',
        subtitle: 'Genera e gestisci i QR CODE',
        type: 'group',
        icon: 'heroicons_outline:qrcode',
        children: [
            {
                id: 'qrcode.scanner',
                title: 'QR CODE scanner',
                type: 'basic',
                icon: 'mat_outline:qr_code_scanner',
                link: '/qrcode/scanner'
            },
            {
                id: 'qrcode.pages',
                title: 'Pagine QR CODE',
                type: 'basic',
                icon: 'heroicons_outline:document',
                link: '/qrcode/pages'
            }
        ]
    },
    {
        type: 'divider'
    },
    {
        id: 'magazzino',
        title: 'Magazzino',
        subtitle: 'Gestisci i prodotti del magazzino',
        type: 'group',
        icon: 'heroicons_outline:users',
        children: [
            {
                id: 'magazzino.estintori',
                title: 'Estintori',
                type: 'basic',
                icon: 'mat_outline:fire_extinguisher',
                link: '/repository/list-by-category/1'
            },
            {
                id: 'magazzino.impianti_idrici',
                title: 'Impianti Idrici Antincendio',
                type: 'basic',
                icon: 'mat_outline:local_fire_department',
                link: '/repository/list-by-category/2'
            },
            {
                id: 'magazzino.impianti_fumi',
                title: 'Impianti Riv. Fumi/Evac',
                type: 'basic',
                icon: 'mat_outline:warning_amber',
                link: '/repository/list-by-category/3'
            },
            {
                id: 'magazzino.porte_e_uscite',
                title: 'Porte e Uscite',
                type: 'basic',
                icon: 'mat_outline:sensor_door',
                link: '/repository/list-by-category/4'
            },
            {
                id: 'magazzino.sicurezza',
                title: 'Sicurezza',
                type: 'basic',
                icon: 'mat_outline:health_and_safety',
                link: '/repository/list-by-category/5'
            },
            {
                id: 'magazzino.altro',
                title: 'Altro',
                type: 'basic',
                icon: 'mat_outline:indeterminate_check_box',
                link: '/repository/list-by-category/1000'
            },
        ]
    },
    {
        type: 'divider'
    },

];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
