import {first} from "rxjs";
import {AuthService} from "../core/auth/auth.service";

export function appInitializer(_authService: AuthService): () => Promise<any> {
    return () =>
        new Promise((resolve) => {
            _authService.signInUsingToken()
                .pipe(first())
                .subscribe({
                    next: (response) => {
                        if (response) {
                            _authService.setAuthenticated(response);
                        }
                        resolve(true)
                    },
                    error: () => {
                        resolve(true)
                    }
                })

        });
}
