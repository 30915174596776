import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ScrollService {

    constructor() {
    }

    scrollToTop() {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    scrollToElementId(elementId: string) {
        setTimeout(() => {
            document.getElementById(elementId).scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest"
            });
        }, 200);
    }

}
