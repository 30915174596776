import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, Observable, of, switchMap, throwError} from 'rxjs';
import {AuthUtils} from 'app/core/auth/auth.utils';
import {UserService} from 'app/core/user/user.service';
import {routes} from "../../routes";
import {Me} from "../../shared/types/me.types";

@Injectable()
export class AuthService {
    private _authenticated: boolean = false;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        return this._httpClient.post('api/auth/forgot-password', email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any> {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }

        return this._httpClient.post(routes.session.login(), credentials).pipe(
            switchMap((response: any) => {
                // Store the access token in the local storage
                this.accessToken = response.token;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response.user;
                this._userService.role = response.role;
                this._userService.currentUser = response;

                // Return a new observable with the response
                return of(response);
            })
        );
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<boolean | object> {
        // Sign in using the token
        return this._httpClient.get<Me>(routes.session.me()).pipe(
            catchError(() =>

                // Return false
                of(false)
            )
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {

        //Call backend to invalidate token
        return this._httpClient.post(routes.session.logout(), {}).pipe(switchMap(() => {
            // Remove the access token from the local storage
            localStorage.removeItem('accessToken');

            // Set the authenticated flag to false
            this._authenticated = false;

            // Return the observable
            return of(true);
        }));
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any> {
        return this._httpClient.post('api/auth/sign-up', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {


        // Check the access token availability
        if (this.accessToken) {
            return of(true);
        } else {
            return of(false);
        }

        // return this.signInUsingToken();
    }

    setAuthenticated(response) {
        console.log(response)
        this._authenticated = true;
        if (response.accessToken) {
            this.accessToken = response.accessToken;
        }

        // Store the user on the user service
        if (response.roles) {
            this._userService.user = response.user;
            this._userService.role = response.role;
            this._userService.currentUser = response;
        }

    }
}
