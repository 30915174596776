import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {User} from 'app/core/user/user.types';
import {Me, Role} from "../../shared/types/me.types";

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);
    private _role: ReplaySubject<Role> = new ReplaySubject<Role>(1);
    private _currentUser: ReplaySubject<Me> = new ReplaySubject<Me>(1);

    /**
     * Constructor
     */
    constructor() {
    }

    set user(value: User) {
        // Store the value
        this._user.next(value);
    }

    get user$(): Observable<User> {
        return this._user.asObservable();
    }


    set role(value: Role) {
        // Store the value
        this._role.next(value);
    }

    get role$(): Observable<Role> {
        return this._role.asObservable();
    }

    set currentUser(value: Me) {
        // Store the value
        this._currentUser.next(value);
    }

    get currentUser$(): Observable<Me> {
        return this._currentUser.asObservable();
    }

}
