import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class WebappService {

    private installPrompt = null;

    constructor() {
    }


    get installPromptValue() {
        return this.installPrompt;
    }

    setInstallPrompt(event: any) {
        this.installPrompt = event;
    }

    async installWebApp() {
        if (!this.installPrompt) {
            return;
        }
        const result = await this.installPrompt.prompt();
        console.log(`Install prompt was: ${result.outcome}`);
        this.installPrompt = null;
    }


}
