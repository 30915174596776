import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {Router} from '@angular/router';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
    ) {
    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(tap(() => {

            },
            (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 503) {
                        this.router.navigate(['error/503']);
                        return;
                    }
                    return;
                }
            }));
    }

}
