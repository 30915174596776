import {MatPaginatorIntl} from "@angular/material/paginator";
import {Injectable} from "@angular/core";

export function CustomMatPaginatorInt() {

    const customPaginatorIntl = new MatPaginatorIntl();

    // constructor() {
    // super();
    customPaginatorIntl.nextPageLabel = 'Prossima pagina';
    customPaginatorIntl.previousPageLabel = 'Pagina precedente';
    customPaginatorIntl.firstPageLabel = 'Prima pagina';
    customPaginatorIntl.lastPageLabel = 'Ultima pagina';
    customPaginatorIntl.getRangeLabel = getRangeLabel;
    // }

    return customPaginatorIntl;

}

let getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length == 0 || pageSize == 0) {
        return `0 of ${length}`;
    }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;
    const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;

    return `Da ${startIndex + 1} a ${endIndex} di ${length}`;
};
