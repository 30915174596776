import {Component, Input} from '@angular/core';
import {Item} from "../../types/item.model";

@Component({
  selector: 'app-item-details',
  templateUrl: './item-details.component.html',
  styleUrls: ['./item-details.component.scss']
})
export class ItemDetailsComponent {

    @Input() item: Item;

}
