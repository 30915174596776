import {Component, Input} from '@angular/core';
import {Scansione} from "../../types/scansione.model";

@Component({
    selector: 'app-status-by-light',
    templateUrl: './status-by-light.component.html',
    styleUrls: ['./status-by-light.component.scss']
})
export class StatusByLightComponent {

    @Input() status: Scansione['status'];
    @Input() size?: number = 3;
    @Input() withText?: boolean = true;

    getDecodedStatus() {
        let text = ''
        switch (this.status) {
            case 'free':
                text = 'Da associare'
                break;
            case 'repository':
                text = 'Associato al prodotto'
                break;
            case 'customer':
                text = 'Associato al prodotto ed al cliente'
                break;
        }
        return text;
    }

    getColorByStatus() {
        let color = '';
        switch (this.status) {
            case 'free':
                color = 'bg-red-600';
                break;
            case 'repository':
                color = 'bg-yellow-500';
                break;
            case 'customer':
                color = 'bg-green-600';
                break;
        }
        return color;
    }

}
