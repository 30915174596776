import {Component} from '@angular/core';
import {WebappService} from "./shared/services/webapp.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    /**
     * Constructor
     */
    constructor(
        private webAppService: WebappService
    ) {
        window.addEventListener("beforeinstallprompt", (event) => {
            event.preventDefault();
            this.webAppService.setInstallPrompt(event)
        });
    }
}
