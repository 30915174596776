import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {catchError, Observable, of, throwError} from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import {Router} from "@angular/router";

@Injectable()
export class AuthInterceptor implements HttpInterceptor
{
    /**
     * Constructor
     */
    constructor(private _authService: AuthService)
    {
    }


    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const authToken = this._authService.accessToken;
        if (authToken) {
            req = req.clone({
                setHeaders: {
                    Accept: 'application/json',
                    Authorization: "Bearer " + authToken
                }
            });
        } else {
            req = req.clone({
                setHeaders: {
                    Accept: 'application/json',
                }
            });
        }
        return next.handle(req);
    }
}
